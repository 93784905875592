@import "@roundupapp/component-library/src/styles/index.scss";






















.footer-row {
	width: 100% !important;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding-bottom: 25px;
	position: absolute;
	bottom: 0px;
}
