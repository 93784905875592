@import "@roundupapp/component-library/src/styles/index.scss";












































































.nav-header {
	background-color: $roundup-primary;
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	z-index: 10;
	&.prominent {
		padding-top: 40px !important;
	}

	.nav-container {
		height: 56px;
	}
	.nav-left-button-wrapper {
		cursor: pointer;
		height: 56px;
		width: 50px;
		position: absolute;
		left: 0px !important;
		.nav-left-button {
			font-size: 18px;
			color: white;
		}
	}

	.nav-center-button-wrapper {
		flex: 2;
		flex-grow: 10;
	}

	.nav-right-button-wrapper {
		position: absolute;
		right: 16px !important;
	}

	.col {
		display: flex;
	}
	.v-icon::after {
		height: 20px;
		width: 20px;
		left: -3px;
		top: 0px;
	}
}
