@import "@roundupapp/component-library/src/styles/index.scss";

























































































































































@import '@/styles/main.scss';

* {
	box-sizing: border-box;
}

body,
html {
	margin: 0px;
	padding: 0px env(safe-area-inset-right) env(safe-area-inset-bottom)
		env(safe-area-inset-left) !important;
	background: $roundup-bone;
	font-family: 'Work Sans', Helvetica, Arial, sans-serif;
	color: $roundup-charcoal;
	height: 100%;

	@media screen and (max-width: 480px) {
		.swal2-shown {
			overflow-y: visible !important;
		}
	}
}
#app {
	min-height: 100%;
	overflow-y: auto;
	font-family: 'Work Sans', Helvetica, Arial, sans-serif;
	touch-action: pan-y;
	box-shadow: 0 3px 30px rgb(0 0 0 / 15%);

	.v-application {
		background: $roundup-bone;
	}
}
.frame {
	max-width: 480px;
	margin: 0px auto 0px;
	border-radius: 14px;
	position: relative;
	height: 100%;
	@media screen and (max-width: 960px) {
		max-width: 480px;
	}
}
.slide-left-enter,
.slide-right-leave-active {
	transform: translate(100%, 0);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-left-leave-active,
.slide-right-enter {
	transform: translate(-100%, 0);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-left-enter-active,
.slide-right-enter-active {
	position: absolute;
	@media screen and (max-width: 480px) {
		top: 0px;
	}
}
.slide-left-enter-active,
.slide-right-leave-active,
.slide-right-enter-active,
.slide-left-leave-active {
	transition: all 250ms ease-in-out;
}
.slide-up-enter,
.slide-down-leave-active {
	transform: translate(0, 100%);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-up-leave-active,
.slide-down-enter {
	transform: translate(0, -100%);
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
}
.slide-up-enter-active,
.slide-down-enter-active {
	position: absolute;
	top: -20px;
	@media screen and (max-width: 480px) {
		top: 0px;
	}
}
.slide-up-enter-active,
.slide-down-leave-active,
.slide-down-enter-active,
.slide-up-leave-active {
	transition: all 0.25s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}

.background-fade {
	background: linear-gradient(180deg, #1cd4e3 0%, #3770fe 100%);
}
.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 100% !important;
}
