@import "@roundupapp/component-library/src/styles/index.scss";





















































































.header-bar::v-deep .v-toolbar__content {
	padding-left: 0px !important;
}
