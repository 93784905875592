@import "@roundupapp/component-library/src/styles/index.scss";



















































































































































.onboarding-dialog {
	min-height: 100vh !important;
	.onboarding-body {
		background-color: white !important;
		height: 100vh !important;

		.stepper-row {
			margin: 0 !important;
			margin-top: 48px !important;
			.stepper {
				background-color: $roundup-light-grey !important;
				height: 4px !important;
				width: 48px;
				margin-left: 8px !important;
				margin-right: 8px !important;
				border-radius: 2px !important;
				&.active {
					background-color: $roundup-light-slate !important;
				}
			}
		}

		.step-title {
			margin-top: 32px !important;
			margin-left: 16px !important;
			margin-right: 16px !important;
			text-align: center !important;
			font-size: 1.5rem !important;
			color: $roundup-primary !important;
			font-family: $font-stack !important;
		}

		.step-body {
			margin-top: 32px !important;
			padding-left: 15% !important;
			padding-right: 15% !important;
			text-align: center !important;
			font-size: 1.25rem !important;
			color: $roundup-slate !important;
			font-family: $font-stack !important;
			font-weight: $font-regular !important;
		}

		.skip-text {
			position: absolute !important;
			bottom: 48px;
			left: 50%;
			transform: translate(-50%, 0);

			text-align: center !important;
			font-size: 1.25rem !important;
			color: $roundup-light-slate !important;
			font-family: $font-stack !important;
			&:hover {
				cursor: pointer !important;
			}
		}

		.login-text {
			margin-top: 32px !important;
			text-align: center;
			color: $roundup-primary !important;
			text-decoration: underline !important;
			&:hover {
				cursor: pointer !important;
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.stepper {
		width: 36px !important;
	}
	.image {
		width: 60%;
		max-height: 200px;
	}
	.skip-text {
		bottom: 24px !important;
	}
}
