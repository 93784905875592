@import "@roundupapp/component-library/src/styles/index.scss";

































































.story-icon {
	margin: auto;
}
