@import "@roundupapp/component-library/src/styles/index.scss";
























































































.nav-template {
	flex: 1;
	overflow-y: auto !important;
	position: relative;
	&-header {
		position: fixed;
		left: 50%;
		top: 0%;
		transform: translateX(-50%);
	}

	&-footer {
		position: fixed;
		left: 50%;
		bottom: 0%;
		transform: translateX(-50%);
	}
	&-alert {
		position: fixed;
		left: 50%;
		bottom: 80px;
		transform: translateX(-50%);
	}
}

.nav-template::v-deep .nav-template-nav-footer {
	position: fixed;
	left: 50%;
	bottom: 0%;
	transform: translateX(-50%);
}
.nav-template::v-deep .nav-template-nav-header {
	position: fixed;
	left: 50%;
	top: 0%;
	transform: translateX(-50%);
}
