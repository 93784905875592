@import "@roundupapp/component-library/src/styles/index.scss";






























































.task {
	min-height: 87px !important;
	width: 100% !important;
	margin: 5px !important;
	margin-left: 0px !important;
	margin-right: 15px !important;
	background: $roundup-purple !important;
	margin-bottom: 15px !important;
	&:hover {
		cursor: pointer;
	}
	p {
		color: white !important;
	}
	.task-title {
		font-size: 0.875rem !important;
		font-weight: $font-medium !important;
	}
}
