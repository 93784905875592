@import "@roundupapp/component-library/src/styles/index.scss";







































































































@import '@/styles/elements.scss';

.nav-footer {
	z-index: 10;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	background: $white;
	max-height: $footer-height !important;

	p {
		font-size: 14px !important;
		color: #747a8d !important;
	}
	.nav-footer-row,
	.nav-footer-col {
		max-height: $footer-height !important;
	}

	.nav-footer-col {
		padding-top: 16px;
	}
	&-icon {
		margin-bottom: 5px;
	}

	.active {
		p,
		i {
			color: $roundup-navy !important;
		}
	}
	.btn {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
