@import "@roundupapp/component-library/src/styles/index.scss";


















p {
	font-size: 0.75rem !important;
	color: $roundup-slate !important;
	a {
		font-size: inherit !important;
	}
}
