$footer-height: 75px;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.outdated-browser {
	padding-top: 75px;
}
.outdated-browser-alert {
	padding: 20px 50px;
	background: #ff6276;
	color: #fff;
	font-size: 18px;
	font-family: Lato, sans-serif;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 9999;
}

// alert style
.donor-app-style {
	position: absolute;
	transform: translateX(-50%);
	margin: auto;
	left: 50%;
	bottom: $footer-height;
}

.roundup-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.roundup-col {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
// Onboarding Step CSS styles
.onboarding-step {
	.number {
		border: 1px solid $roundup-charcoal;
		border-radius: 1rem;
		width: 1.5em;
		height: 1.5em;
		text-align: center;
	}
}

.vcenter {
	align-items: center !important;
	display: flex !important;
}

.ellipsis {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
}

.most-popular {
	font-weight: 400;
	font-family: 'Yellowtail', cursive;
	color: $roundup-cerulean !important;
	font-size: 1.25rem !important;
}

body > #hubspot-messages-iframe-container.widget-align-right {
	bottom: 70px !important;
}

.google-btn {
	width: 210px;
	min-width: 280px;
	height: 42px;
	background-color: white;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
	.google-icon-wrapper {
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		width: 40px;
		height: 40px;
		border-radius: 2px;
	}
	.google-icon {
		position: absolute;
		margin-top: 11px;
		margin-left: 21px;
		width: 18px;
		height: 18px;
	}
	.btn-text {
		float: left;
		margin: 11px 11px 0 70px;
		color: $roundup-slate;
		font-size: 16px;
		font-weight: 300;
	}
	&:hover {
		cursor: pointer;
	}
	&:active {
		background: #ebebeb;
	}
}

.facebook-btn {
	width: 210px;
	height: 42px;
	background-color: #4267b2;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
	.facebook-icon-wrapper {
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		width: 40px;
		height: 40px;
		border-radius: 2px;
		background-color: $white;
	}
	.facebook-icon {
		position: absolute;
		margin-top: 11px;
		margin-left: 11px;
		width: 18px;
		height: 18px;
	}
	.btn-text {
		float: right;
		margin: 11px 11px 0 0;
		color: $white;
		font-size: 14px;
		letter-spacing: 0.2px;
		font-family: 'Roboto';
	}
	&:hover {
		box-shadow: 0 0 6px #4267b2;
	}
	&:active {
		background: #5576ba;
	}
}
.apple-btn {
	cursor: pointer;
	width: 184px;
	min-width: 280px;
	height: 42px;
	background-color: black;
	border-radius: 2px;
	box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
	.apple-icon-wrapper {
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		width: 40px;
		height: 40px;
		border-radius: 2px;
		background-color: black;
	}
	.apple-icon {
		position: absolute;
		margin-top: 11px;
		margin-left: 21px;
		width: 18px;
		height: 18px;
	}
	.btn-text {
		float: left;
		margin: 11px 11px 0 70px;
		color: $white;
		font-size: 16px;
		font-weight: 300;
	}
	&:hover {
		box-shadow: 0 0 6px black;
	}
	&:active {
		background: grey;
	}
}
