@import "@roundupapp/component-library/src/styles/index.scss";




































































.nav-header {
	background: $roundup-primary !important;
	max-width: 480px !important;
	width: 100%;
	margin: 0 auto;
	z-index: 10;
	.nav-container {
		height: 56px;
	}
	.nav-left-button-wrapper {
		justify-content: flex-start;
		margin-left: 24px;
		margin-bottom: 3px;
		flex: 1;
		.nav-left-button {
			font-size: 18px;
			color: white;
		}
	}
	.nav-center-button-wrapper {
		flex: 2;
		margin-left: 16px;
	}
	.nav-right-button-wrapper {
		flex: 1;
		justify-content: flex-end;
		padding-right: 24px;
	}
	.col {
		display: flex;
	}
	.v-icon::after {
		height: 20px;
		width: 20px;
		left: -3px;
		top: 0px;
	}
}
