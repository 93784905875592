@import "@roundupapp/component-library/src/styles/index.scss";







































































































































































a {
	text-decoration: none;
}
.icon {
	color: white;
}
.mt-2 {
	margin-top: 8px !important;
}
.background-white {
	background: white !important;
}
.background-white::v-deep .share-btn {
	background: $roundup-bone !important;
	::v-deep i {
		color: $roundup-cerulean !important;
	}
}
