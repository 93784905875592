@import "@roundupapp/component-library/src/styles/index.scss";
























































































































.progress-bar {
	border-radius: 5px;
	width: 100%;
	padding: 5px;
	position: relative;
	h4 {
		color: $roundup-navy;
		margin: 0px;
		margin-bottom: 5px;
	}
	.shell {
		position: relative;

		height: 40px;
		width: 100%;
		border: 1px solid #1cd4e3;
		border-radius: 20px;
		background: $roundup-bone;
	}
	.min-line-divider {
		position: absolute;
		left: 50%;
		top: -5%;
		transform: translateX(-50%);
		border-color: #2553cd !important;
	}
	.min-line-value {
		position: absolute;
		left: 50%;
		top: -25%;
		transform: translateX(-50%);
		color: #2553cd;
		font-size: 12px;
	}
	&.empty .bar {
		background: $roundup-light-grey;
	}
	.bar {
		background: #c6f4f8;
		border-right: 1px solid #1cd4e3;

		height: 38px;
		width: 15px;
		border-radius: 20px;
		span {
			float: right;
			height: 100%;
			display: flex;
			align-items: center;
			padding: 4px 5px;
			color: $roundup-charcoal;
			font-size: 14px;
		}
	}
}
