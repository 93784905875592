@import "@roundupapp/component-library/src/styles/index.scss";











































































































































































































.line-graph {
	padding: 10px 20px;
	height: 300px;
}
